.email_token_confirmation {
  flex: 1;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
}

.email_token_confirmation .profile {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 3em;
  border-bottom: 0.05em solid #999999;
  margin-bottom: 5em;
}

.email_token_confirmation .profile h1 {
  margin: 1em;
}

.email_token_confirmation .messages {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.email_token_confirmation .link {
  margin: 1em;
  display: flex;
  color: #999999;
}
